<template>
<div class="app" v-if="!started">
  <div class="container">
    <div class="row justify-content-center">
    <div class="col-12">
      <br><br>
      <h2><i class="bi bi-compass"></i> Compass game demo</h2>
      <button class="btn btn-success form-control" type="button" v-on:click="startNewGame()">Start new session</button>
      <br><br>
      <i>or enter code you friend sent you</i>
      <br><br>
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="session code" v-model="this.sessionInput" aria-label="code" aria-describedby="submitButton">
        <button class="btn btn-primary" type="button" id="submitButton" v-on:click="startGameFromSession()">Start</button>
      </div>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="this.miles">
        <label class="form-check-label" for="flexSwitchCheckDefault">Use miles instead kilometers</label>
      </div>
    </div>
  </div>
  </div>
</div>
<div class="app" :class="{ rightWay: isRightWay }" v-if="started">
    <br><br>
    <div class="compass">
      <div class="arrow"></div>
      <div class="compass-circle"></div>
      <div class="my-point"></div>
    </div>
    <div class="rightWayMessage" v-if="isRightWay">
      <h2>You are on the right way!</h2><br>
      {{this.distance}} <span v-if='this.miles'>miles</span><span v-if='!this.miles'>km</span> to destination.
      <span v-if="this.distance > 20"><br><i>Very far!</i></span>
      <span v-if="this.distance < 1"><br><i>Too close. May work incorrectly.</i></span>
    </div>
    <br>
    <div class="shareLink" v-if="!isRightWay">
    <span class="lostConnection" v-if="this.user2lat == 0 && this.user2lng == 0"><i><i class="bi bi-hourglass"></i> Waiting for second player.</i><br><br></span>
      <span class="lostConnection" v-if="this.lostConnection"><i><i class="bi bi-ethernet"></i> No connection with network.</i><br></span>
      <!--users: {{this.user1}} | {{this.user2}}<br>
      user1 coords: {{this.user1lat}} {{this.user1lng}}<br>
      user2 coords: {{this.user2lat}} {{this.user2lng}}<br>-->
      <span v-if="this.user2lat != 0 && this.user2lng != 0">
        {{this.distance}} <span v-if='this.miles'>miles</span><span v-if='!this.miles'>km</span> to destination.<br>
      </span>
      <span v-if="!this.isIAmASecondPlayer">
        <button class="btn btn-outline-primary" type="button" v-on:click="copyToClipboard()" style="margin-right: 1rem;"><span v-if="!this.isCopied"><i class="bi bi-clipboard"></i> Copy session code</span><span v-if="this.isCopied"><i class="bi bi-check-lg"></i> Copied!</span></button>
        <button class="btn btn-outline-success" type="button" v-on:click="shareButton()"><i class="bi bi-share"></i> Share link</button>
      </span>
      <!--<span v-if="!this.isIAmASecondPlayer">send this code to second player: {{this.user2}}{{this.sessionId}}</span>-->
      <br>
    </div>
</div>
</template>

<script>

export default {
  name: 'CompassGame',
  data(){
    return {
      isios: false,
      compassCircle: null,
      pointDegree: 0,
      isRightWay: false,
      lat:null,
      lng:null,
      myLat:null,
      myLng:null,
      distance: null,
      lostConnection: false,
      started: false,
      getSessionParam: null,
      sessionId: null,
      user1: null,
      user2: null,
      user1lat: 0,
      user2lng: 0,
      user1lng: 0,
      user2lat: 0,
      sessionInput: null,
      miles: false,
      isIAmASecondPlayer: false,
      isCopied: false,
    }
  },
  created() {
    let uri = window.location.href.split('?');
    if(uri.length == 2) {
      let vars = uri[1].split('&');
      let getVars = {};
      let tmp = '';
      vars.forEach(function(v) {
        tmp = v.split('=');
        if(tmp.length == 2)
          getVars[tmp[0]] = tmp[1];
      });
      if(getVars.session) {
        this.getSessionParam = getVars.session
      }
    }
  },
  mounted() {
    this.sessionInput = this.getSessionParam;
    this.isios = navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);

    navigator.geolocation.getCurrentPosition(this.locationHandler,this.errorHandler,{timeout:10000});

    if (!this.isios) {
      window.addEventListener("deviceorientationabsolute", this.handler, true);
    }

    setInterval(() => {
        this.updateData();
        navigator.geolocation.getCurrentPosition(this.locationHandler,this.errorHandler,{timeout:10000});
    }, 5000);

  },
  methods:{
      copyToClipboard(){
        navigator.clipboard.writeText(this.user2+""+this.sessionId);
        this.isCopied = true;
        setTimeout(() => {
          this.isCopied = false;
        }, 2000);
      },
      shareButton(){
        const shareData = {
          title: 'https://compass.drusha.me/?session='+this.user2+''+this.sessionId,
          text: 'https://compass.drusha.me/?session='+this.user2+''+this.sessionId,
          url: 'https://compass.drusha.me/?session='+this.user2+''+this.sessionId,
        }
        navigator.share(shareData)
      },
      handler(e) {
        this.compassCircle = document.querySelector(".compass-circle");
        let compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
        this.compassCircle.style.transform = `translate(-50%, -50%) rotate(${-compass}deg)`;

        if (
          (this.pointDegree < Math.abs(compass) &&
            this.pointDegree + 50 > Math.abs(compass)) ||
          this.pointDegree > Math.abs(compass + 50) ||
          this.pointDegree < Math.abs(compass)
        ) {
          this.isRightWay = false;
        } else if (this.pointDegree) {
          if(this.user2lat == 0 && this.user2lng == 0){
            this.isRightWay = false;
          } else {
            this.isRightWay = true;
          }
        }
        },
        errorHandler(error) {
          console.log(error);
        },
        startCompass() {
          this.started = true;
            if (this.isios) {
              DeviceOrientationEvent.requestPermission()
                .then((response) => {
                  console.log(response)
                  if (response === "granted") {
                    window.addEventListener("deviceorientation", this.handler, true);
                    this.started = true;
                  } else {
                    alert("Device Orientation Request has to be granted.");
                  }
                })
                .catch(() => alert("Device orientation API is not supported or not enabled on this device."));
            }
            
        },
        startNewGame(){
          this.createNewSession();
          this.startCompass();
        },
        async startGameFromSession(){
          this.user1 = this.sessionInput.slice(0,5); 
          this.sessionId = this.sessionInput.slice(5);

          let result = await this.getSessionData(this.sessionId);
          if(result){
            this.user2 = result.user1;
            this.isIAmASecondPlayer = true;
            this.startCompass();
          }

        },
        locationHandler(position) {
          const { latitude, longitude } = position.coords;
          this.pointDegree = this.calcDegreeToPoint(latitude, longitude);
          this.myLat = latitude;
          this.myLng = longitude;
          this.distance = this.calcCrow(this.user1lat, this.user1lng, this.user2lat, this.user2lng);

          if (this.pointDegree < 0) {
            this.pointDegree = this.pointDegree + 360;
          }
        },
        calcDegreeToPoint(latitude, longitude) {

          this.lat = this.user2lat;
          this.lng = this.user2lng;

          const phiK = (this.user2lat * Math.PI) / 180.0;
          const lambdaK = (this.user2lng * Math.PI) / 180.0;
          const phi = (latitude * Math.PI) / 180.0;
          const lambda = (longitude * Math.PI) / 180.0;
          const psi =
            (180.0 / Math.PI) *
            Math.atan2(
              Math.sin(lambdaK - lambda),
              Math.cos(phi) * Math.tan(phiK) -
                Math.sin(phi) * Math.cos(lambdaK - lambda)
            );
          //console.log(psi)
          return Math.round(psi);
        },
        calcCrow(lat1, lon1, lat2, lon2) 
          {

            var R = 6378.8; // Radius of earth in kilometers

            if(this.miles){
              R = 3958.8; // Radius of earth in miles
            }
            var dLat = this.toRad(lat2-lat1);
            var dLon = this.toRad(lon2-lon1);
            lat1 = this.toRad(lat1);
            lat2 = this.toRad(lat2);

            var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
            var d = R * c;
            return d.toFixed(2);
        },
        toRad(x) {
          return x * Math.PI / 180;
        },
        async updateData(){
          // Send the data to the backend
          if(this.sessionId && this.user1){
              //console.log("updating data")
              //console.log(this.myLat+" "+this.myLng) 
              this.updateCoordinates(this.user1, this.myLat, this.myLng);
              this.user1lat = this.myLat;
              this.user1lng = this.myLng;
          }

          // Get data from backend
          if(this.sessionId && this.user2){
            //console.log("getting data from backend")
            let response = await this.getUserCoordinates(this.user2);
            if(response){
              this.user2lat = response.lat;
              this.user2lng = response.lng;
            }
          }

        },
        async createNewSession(){
          const res = await fetch(
            'https://api.compass.drusha.me/?m=createSession'
          );
          const data = await res.json();
          this.sessionId = data.session_id;
          this.user1 = data.user1;
          this.user2 = data.user2;
        },
        async updateCoordinates(hash, lat, lng) {
          const res = await fetch(
            'https://api.compass.drusha.me/?m=sendCoords&lat='+lat+'&lng='+lng+'&user='+hash
          );
          await res.json();
          //console.log(data);
        },
        async getUserCoordinates(hash){
          const res = await fetch(
            'https://api.compass.drusha.me/?m=getUserCoordinates&user='+hash
          );
          const data = await res.json();
          return data;
        },
        async getSessionData(sessionId){
          const res = await fetch(
            'https://api.compass.drusha.me/?m=getSessionData&session='+sessionId
          );
          const data = await res.json();
          return data;
        }
  }
}
</script>

<style>
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden
}
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.app{ 
    position:fixed;
    padding:0;
    margin:0;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.rightWay{
  background-color: #00B74A !important;
  transition: background-color 0.5s ease;
}
.rightWayMessage{
  color: #fff;
}
.compass {
  position: relative;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  margin: auto;
  background-color: #fff;
}

.compass > .arrow {
  position: absolute;
  width: 0;
  height: 0;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  border-style: solid;
  border-width: 30px 20px 0 20px;
  border-color: red transparent transparent transparent;
  z-index: 1;
}

.compass > .compass-circle,
.compass > .my-point {
  position: absolute;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease-out;
  background: url(https://purepng.com/public/uploads/large/purepng.com-compasscompassinstrumentnavigationcardinal-directionspointsdiagram-1701527842316onq7x.png)
    center no-repeat;
  background-size: contain;
}

.compass > .my-point {
  opacity: 0;
  width: 20%;
  height: 20%;
  background: rgb(8, 223, 69);
  border-radius: 50%;
  transition: opacity 0.5s ease-out;
}

.lostConnection{
  color: red;
}
</style>
